@import '~assets/variables';
@import '~assets/mixins';

.container {
  display: grid;

  text-align: center;

  width: 100%;
  min-height: 50rem;

  & .error {
    direction: ltr;

    font-size: 5rem;

    color: $primary-light;

    align-self: center;

    @media (max-width: $tablet-screen) {
      font-size: 3.2rem;
    }
  }

  & .back {
    font-size: 1.5rem;

    color: $secondary;

    align-self: center;

    @media (max-width: $tablet-screen) {
      font-size: 1.2rem;
    }
  }
}
