@import '~assets/variables';
@import '~assets/mixins';

.details-container {
  @include flex-display($flexDirection: column, $gap: 8px);

  & .detail {
    @include flex-display(
      $alignItems: center,
      $justifyContent: space-between,
      $gap: 8px
    );

    & .detail-label,
    & .detail-value {
      width: 100%;
    }

    & .detail-label {
      font-weight: bold;
    }
  }

  & .address-area {
    @include flex-display($wrap: wrap);

    & .address {
      @include flex-display($alignItems: center, $justifyContent: flex-end);

      width: 100%;

      & .address-icon {
        list-style-type: none;

        & .address-icon-element {
          position: relative;

          cursor: pointer;

          &.copy-area {
            @include flex-display(
              $alignItems: center,
              $justifyContent: space-between,
              $gap: 8px
            );
          }

          & svg {
            width: 20px;
            height: 20px;
          }

          & .qr-code {
            background: $secondary-theme;

            border: 1px solid $secondary-border;
            border-radius: 2px;

            width: 150px;
            height: 150px;

            position: absolute;
            top: -160px;
            display: none;

            transition: all ease-in-out 0.5s;

            & .qr-code-img {
              width: 100px;
              height: 100px;

              margin: 20px auto;
              display: block;

              border-radius: 0px;
              border: 1px solid $secondary-border;
            }
          }
        }

        & .address-icon-element:first-child:hover .qr-code {
          display: block;
          transition: all ease-in-out 0.5s;
        }

        @media (max-width: $mobile-screen) {
          & .address-icon-element:first-child svg {
            width: 0px;
          }
        }
      }
    }
  }

  & .qr-code-area {
    display: none;
    background: $secondary-theme;

    width: 100%;
    height: 100%;

    transition: all ease-in-out 0.5s;

    @media (max-width: $mobile-screen) {
      display: block;
    }
    & .qr-code-img {
      width: 150px;
      height: 150px;

      margin: 20px auto;
      display: block;

      border-radius: 0px;
      border: 1px solid $secondary-border;
    }
  }
}

html[dir='rtl'] .details-container {
  & .detail-value {
    text-align: left;
  }
}

html[dir='ltr'] .details-container {
  & .detail-value {
    text-align: right;
  }
}
