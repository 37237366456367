@import '~assets/variables';
@import '~assets/mixins';

.social-list {
  @include flex-display(
    $alignItems: center,
    $justifyContent: flex-start,
    $gap: 15px
  );

  list-style-type: none;

  width: 100%;

  & .copy-item {
    cursor: pointer;

    path {
      fill: $primary;
    }
  }

  & .social-copied {
    color: $primary;
  }

  & .social-item {
    cursor: pointer;

    & svg {
      margin-top: 4px;

      width: 24px;
      height: auto;

      & path {
        fill: $primary;
      }
    }
  }
}
