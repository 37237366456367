@mixin label() {
  & .label {
    position: relative;

    font-size: 1.6rem;

    display: block;

    color: $primary-light;

    margin-bottom: 12px;

    user-select: none;

    @media (max-width: $tablet-screen) {
      margin-bottom: 8px;
    }

    @media (max-width: $x-small-mobile-screen) {
      font-size: $size-very-small;
    }

    &.label-button {
      font-family: $font-Bold;
      line-height: 3.6rem;
      text-align: center;
      white-space: nowrap;

      background-color: transparent;
      color: $primary;

      width: 100%;
      height: 48px;

      margin: 0;
      padding: 5px 25px;

      border: 2px solid $primary;
      border-radius: 2px;

      user-select: none;

      cursor: pointer;

      & + .input {
        display: none;
      }
    }
  }
}

@mixin placeholder() {
  font-size: 1.4rem;

  color: $primary-placeholder;

  @media (max-width: $tablet-screen) {
    font-size: 1.2rem;
  }
}

@mixin inputDefaultClasses() {
  &.small {
    max-width: 6.5rem;

    margin-bottom: 12px;
  }

  &::placeholder {
    @include placeholder();
  }

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }
}

@mixin input() {
  font-size: 1.6rem;

  -webkit-appearance: none;
  -moz-appearance: none;

  color: $primary-light;

  width: 100%;
  height: 48px;

  padding: 12px 16px;

  border-radius: 8px;
  border: 2px solid $primary-light;
}

@mixin caretDownBackground() {
  background: transparent;
  background-image: url('~assets/icons/caretDown.svg');
  background-repeat: no-repeat;
  background-position-y: 17px;
  background-size: 12px;
}

@mixin defaultSelect() {
  @include label();

  position: relative;

  &.error .select {
    border-color: $primary-error;
  }

  & .select,
  & .input {
    @include inputDefaultClasses();
    @include input();
    @include caretDownBackground();
  }
}
