@import '~assets/mixins';

.container {
  @include flex-display($alignItems: center, $justifyContent: center);

  & > div {
    margin: 48px 70px 0;

    @media (max-width: $tablet-screen) {
      margin: 24px 16px;
      padding: 16px;

      & button {
        height: 48px;
      }
    }
  }
}
