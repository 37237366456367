@import '~assets/variables';
@import '~assets/mixins';

.pagination-container {
  @include flex-display($justifyContent: flex-start);

  list-style-type: none;

  width: 100%;

  & .pagination-item {
    @include flex-display($alignItems: center);

    font-size: $size-very-small;
    line-height: 1.4;
    text-align: center;

    color: $primary-text;

    height: 30px;
    min-width: 30px;

    padding: 10px 14px;
    margin: 0px;

    box-sizing: border-box;
    border: 1px solid $primary-border;

    @media (max-width: $tablet-screen) {
      line-height: 30px;
      text-align: center;

      padding: 0px;

      display: block;
    }

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      cursor: pointer;
    }

    &.selected {
      background-color: $primary-border;
    }

    & .arrow {
      &.left {
        & svg {
          width: 10px;
          transform: rotate(90deg);
        }
      }

      &.right {
        & svg {
          width: 10px;
          transform: rotate(-90deg);
        }
      }
    }

    &.disabled {
      border-color: $primary-disabled;
      color: $primary-disabled;

      cursor: not-allowed;

      & svg {
        fill: $primary-disabled;
      }

      &:hover {
        background-color: transparent;

        cursor: not-allowed;
      }
    }
  }
}
