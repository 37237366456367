@import '~assets/variables';
@import '~assets/mixins';

.site-navbar {
  position: sticky;
  top: 0;

  background-color: $secondary-theme;

  border-bottom: 4px solid $primary-border;

  z-index: 6;

  & .container {
    @include flex-display(center, space-between);
    @include container;

    height: 84px;

    padding: 10px 70px;

    @media (max-width: $tablet-screen) {
      height: 60px;

      padding: 25px 16px;
    }
  }
}
