// Here lies the related styles for LoadingSpinner.js

@import '~assets/variables';
@import '~assets/mixins';

$offset: 187;
$duration: 1.4s;

.container {
  @include flex-display(center, center);

  &.default {
    min-height: 50rem;
    height: 100%;
  }

  &.no-min-height {
    height: 100%;
  }

  &.custom-loader-container {
    @include flex-display(center, center);

    height: 100vh;
  }

  .spinner {
    width: 30px;
    height: 30px;

    animation: rotator $duration linear infinite;

    & .path {
      stroke-dasharray: $offset;
      stroke-dashoffset: 0;

      transform-origin: center;

      &.path-animation-stroke-white {
        animation: dash $duration ease-in-out infinite,
          stroke-white ($duration * 4) ease-in-out infinite;
      }

      &.path-animation-stroke-dark {
        animation: dash $duration ease-in-out infinite,
          stroke-dark ($duration * 4) ease-in-out infinite;
      }

      &.path-animation-stroke-other {
        animation: dash $duration ease-in-out infinite,
          stroke-other ($duration * 4) ease-in-out infinite;
      }
    }
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes stroke-white {
  0%,
  100% {
    stroke: $primary-light;
  }
}

@keyframes stroke-dark {
  0%,
  100% {
    stroke: $primary-dark;
  }
}

@keyframes stroke-other {
  0%,
  100% {
    stroke: $primary;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: calc($offset/4);

    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;

    transform: rotate(450deg);
  }
}
