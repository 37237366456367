@import '~assets/variables';
@import '~assets/mixins';

.site-footer {
  background-color: $tertiary-theme;

  position: relative;
  overflow: hidden;

  & .container {
    @include grid-display($columns: repeat(3, 1fr));

    @include container;

    padding: 0px 70px 0px;

    @media (max-width: $tablet-screen) {
      @include flex-display(
        $alignItems: center,
        $justifyContent: center,
        $flexDirection: column,
        $gap: 24px
      );
    }

    & .start-container {
      @media (max-width: $tablet-screen) {
        text-align: center;

        margin: 3rem 0;
      }

      & .top-container {
        padding: 3rem 0 2rem;

        @media (max-width: $tablet-screen) {
          padding: 0 0 2rem;
        }

        & .text-container {
          & .text-title {
            font-size: $size-default;

            margin: 10px 0px;
          }

          & .text-description {
            font-size: $size-small;
            line-height: 20px;
            text-align: justify;

            @media (max-width: $tablet-screen) {
              text-align: center;
            }
          }
        }
      }

      & .button-container {
        @include flex-display(
          $alignItems: center,
          $justifyContent: flex-start,
          $gap: 2rem
        );

        padding: 3rem 0;

        @media (max-width: $tablet-screen) {
          justify-content: center;

          padding: unset;
        }

        & .telephone svg {
          width: 16px;

          @media (max-width: $tablet-screen) {
            width: 12px;
          }
        }
      }
    }

    & .end-container {
      position: inherit;

      grid-column: 2/4;

      background-color: $tertiary-theme;

      width: 100%;

      z-index: 2;

      & .list {
        list-style: none;

        &.navigation {
          font-weight: bold;

          columns: 3;
          column-gap: 0.2rem;

          padding: 8rem 0 5rem;

          @media (max-width: $medium-tablet-screen) {
            columns: 2;
            column-gap: unset;

            padding: 5rem 0 3rem;
          }

          @media (max-width: $tablet-screen) {
            padding: 1rem;
          }
        }

        &.social-icons {
          @include flex-display($alignItems: center, $justifyContent: center);

          @media (max-width: $tablet-screen) {
            gap: 15px;
          }
        }

        & .item {
          margin-bottom: 16px;

          & > a {
            font-size: $size-default;

            color: $primary-text;

            text-decoration: none;

            @media (max-width: $tablet-screen) {
              font-size: $size-small;
            }
          }
        }
      }

      & .line {
        @media (max-width: $tablet-screen) {
          top: 86%;
        }
      }

      & .social {
        @include flex-display(
          $alignItems: center,
          $justifyContent: flex-end,
          $gap: 2.4rem
        );

        width: 100%;

        padding: 3rem 0;

        @media (max-width: $medium-tablet-screen) {
          flex-wrap: wrap;
          justify-content: flex-start;
        }

        @media (max-width: $tablet-screen) {
          justify-content: center;
        }

        & .text {
          font-size: $size-small;
          font-weight: bold;

          color: $primary-text;

          @media (max-width: $tablet-screen) {
            display: none;
          }

          @media (max-width: $tablet-screen) {
            font-size: $size-very-small;

            margin-bottom: 12px;
          }
        }

        & .social-item svg {
          width: 32px;
          height: 25px;

          @media (max-width: $tablet-screen) {
            font-size: $size-very-small;

            width: 28px;
            height: 21px;
          }
        }
      }
    }

    & .line {
      position: absolute;

      height: 6px;
      width: 100%;

      background: $secondary-theme;

      z-index: 1;

      @media (max-width: $tablet-screen) {
        top: 56%;

        background: $tertiary-gradient-border;

        transform: rotate(-180deg);

        z-index: 3;
      }
    }
  }
}

html[dir='rtl'] .site-footer {
  & .start-container {
    padding-left: 5rem;

    border-left: 6px solid $secondary-theme;

    @media (max-width: $tablet-screen) {
      padding-left: unset;

      border-left: unset;
    }

    & .line {
      left: 30%;
    }
  }

  & .end-container {
    & .list {
      &.navigation {
        padding-right: 5rem;

        @media (max-width: $medium-tablet-screen) {
          padding-right: 2rem;
        }

        @media (max-width: $tablet-screen) {
          padding-right: unset;
        }
      }
    }

    & .line {
      @media (max-width: $tablet-screen) {
        right: 0;
      }
    }

    & .social {
      @media (max-width: $medium-tablet-screen) {
        padding-right: 2rem;
      }

      @media (max-width: $tablet-screen) {
        padding-right: unset;
      }
    }
  }
}

html[dir='ltr'] .site-footer {
  & .start-container {
    padding-right: 5rem;

    border-right: 6px solid $secondary-theme;

    @media (max-width: $tablet-screen) {
      padding-right: unset;

      border-right: unset;
    }

    & .line {
      right: 30%;
    }
  }

  & .end-container {
    & .list {
      &.navigation {
        padding-left: 5rem;

        @media (max-width: $medium-tablet-screen) {
          padding-left: 2rem;
        }

        @media (max-width: $tablet-screen) {
          padding-left: unset;
        }
      }
    }

    & .line {
      @media (max-width: $tablet-screen) {
        left: 0;
      }
    }

    & .social {
      @media (max-width: $medium-tablet-screen) {
        padding-left: 2rem;
      }

      @media (max-width: $tablet-screen) {
        padding-left: unset;
      }
    }
  }
}
