@import '~assets/variables';
@import '~assets/mixins';

.container {
  position: relative;

  & .overlay {
    position: fixed;
    inset: 0;
  }

  & button {
    width: 9.9rem;
    height: 4rem;

    &.active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
    }
  }

  @media (max-width: $medium-tablet-screen) {
    position: absolute;

    & button {
      width: 7.9rem;
      height: 3.5rem;

      padding: unset;

      & > span[class*='text'] {
        font-size: $size-very-small;
      }
    }

    & .list {
      top: 31px;

      font-size: $size-very-small;
    }
  }
}

.list {
  @include flex-display(
    $alignItems: center,
    $justifyContent: center,
    $flexDirection: column,
    $gap: 8px
  );

  font-size: $size-small;

  position: absolute;
  top: 38px;

  width: 100%;

  background-color: $secondary-theme;

  list-style: none;

  border: 1px solid $primary;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  padding: 8px 0;

  &[aria-hidden='true'] {
    display: none;
  }

  & li {
    color: $primary-light;

    cursor: pointer;
  }
}

html[dir='rtl'] .container {
  @media (max-width: $medium-tablet-screen) {
    left: 5%;
  }
}

html[dir='ltr'] .container {
  @media (max-width: $medium-tablet-screen) {
    right: 5%;
  }
}
