@import '~assets/variables';
@import '~assets/mixins';

.profile-info {
  @include flex-display(
    $justifyContent: center,
    $alignItems: flex-start,
    $flexDirection: column,
    $gap: 16px
  );

  @media (max-width: $large-screen) {
    gap: 12px;
  }

  & .total-balance {
    @include flex-display(
      $justifyContent: space-between,
      $alignItems: center,
      $gap: 5px,
      $wrap: wrap
    );

    width: 100%;

    @media (max-width: $large-screen) {
      gap: 4px;
    }
  }

  & .balance-value {
    @include flex-display(
      $justifyContent: space-between,
      $alignItems: center,
      $gap: 3px
    );
  }

  & .loading {
    align-self: center;
  }
}
