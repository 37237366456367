@import '~assets/variables';
@import '~assets/mixins';

.container {
  @include flex-display(center, center, column, $gap: 45px);

  width: 100%;
  min-height: 90rem;

  @media (max-width: $tablet-screen) {
    gap: 30px;

    min-height: 60rem;
  }

  & .title {
    width: 940px;

    @media (max-width: $large-screen) {
      width: 600px;
    }

    @media (max-width: $tablet-screen) {
      width: 360px;
    }

    @media (max-width: $mobile-screen) {
      width: 270px;
    }

    & svg {
      width: 100%;
    }
  }

  & .description {
    color: $primary-light;

    margin-top: 50px;

    @media (max-width: $tablet-screen) {
      margin-top: 20px;
    }
  }

  & .back {
    max-width: 40rem;

    @media (max-width: $tablet-screen) {
      max-width: 30rem;
    }
  }
}
