@import '~assets/mixins';

.container {
  @include flex-display($justifyContent: center, $alignItems: center);

  height: 100vh;

  & picture img {
    width: 20rem;
    height: 10.5rem;

    animation: fadeInAndOut 1s linear infinite;

    @keyframes fadeInAndOut {
      0% {
        opacity: 0;
      }
      30% {
        opacity: 0.5;
      }
      50% {
        opacity: 1;
      }
      70% {
        opacity: 0.5;
      }
      100% {
        opacity: 0;
      }
    }
  }
}
