@import '~assets/variables';
@import '~assets/mixins';

.section {
  @include flex-display($flexDirection: column);

  & .header {
    @include flex-display($alignItems: center, $justifyContent: space-between);

    background-color: $quaternary-theme;
    color: $primary-light;

    padding: 18px 32px;

    transition: background-color 0.6s ease;

    cursor: pointer;

    &.active {
      border-bottom: 1px solid $primary-border;

      transition: border-bottom 0.6s ease;
    }

    & button {
      min-width: 144px;

      @media (max-width: $tablet-screen) {
        min-width: unset;
      }

      & .button {
        flex-direction: row-reverse;
      }

      & .caret {
        @include flex-display(
          $alignItems: center,
          $justifyContent: space-between
        );

        fill: $primary-dark;

        transition: transform 0.6s ease;

        &.rotate {
          transform: rotate(180deg);
        }
      }
    }
  }

  & .content {
    background-color: $quaternary-theme;

    overflow: auto;

    transition: max-height 0.6s ease;

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
