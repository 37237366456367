@import '~assets/variables';
@import '~assets/mixins';
@import '../mixins.module.scss';

.select-control {
  @mixin iconContainer() {
    & .icon-container {
      @include flex-display($alignItems: center);

      & svg {
        width: 33px;
        height: 33px;
      }
    }
  }

  @include label();

  position: relative;

  &.error .select-value {
    border-color: $primary-error;
  }

  & .select-value {
    @include flex-display($alignItems: center, $justifyContent: stretch);
    @include input();
    @include caretDownBackground();

    position: relative;

    cursor: text;

    & .selected-option {
      @include iconContainer();
      @include flex-display($alignItems: center, $gap: 2px);

      position: absolute;
      top: 50%;

      padding: 0 16px;

      transform: translateY(-50%);

      box-sizing: border-box;

      & .icon-container {
        & picture,
        & img {
          width: 35px;
          height: 35px;
        }
      }

      & .label {
        margin: 0;

        & .name {
          font-size: $size-default;
        }

        & .symbol {
          font-size: $size-small;
        }
      }

      & .placeholder {
        @include placeholder();

        position: relative;

        z-index: 0;

        user-select: none;
      }
    }

    & .input-container {
      & .input {
        box-sizing: content-box;

        padding: 0;

        border: none;

        opacity: 1;

        outline: none;
      }

      & .input-value {
        font-size: 1.6rem;

        background: transparent;
        caret-color: $primary-light;
        color: transparent;

        position: absolute;
        top: 16px;

        color: $primary-light;

        height: auto;

        outline: none;
      }
    }
  }

  & .overlay {
    @include overlay();

    background-color: transparent;
  }

  & .options-container {
    @include scrollbar();

    position: absolute;

    background-color: $secondary-theme;
    color: $primary-light;

    width: 100%;
    max-height: 30rem;

    padding: 24px 0;

    border: 2px solid $primary-light;
    border-top: none;
    border-radius: 8px;

    overflow-y: auto;

    z-index: 3;

    &::-webkit-scrollbar {
      width: 25px;
    }

    &::-webkit-scrollbar-thumb {
      background: $primary-text;

      background-clip: padding-box;

      border: 10px solid transparent;
      border-radius: 30px;
    }

    & .list {
      @include flex-display(
        $alignItems: flex-start,
        $flexDirection: column,
        $gap: 18px
      );

      list-style: none;

      & .option {
        @include iconContainer();
        @include flex-display($alignItems: center, $gap: 4px);

        width: 100%;

        padding: 0 16px;

        cursor: pointer;

        & .icon-container {
          & picture,
          & img {
            width: 35px;
            height: 35px;
          }
        }

        & .label {
          @include flex-display(
            $alignItems: flex-start,
            $flexDirection: column,
            $gap: 4px
          );

          margin: 0;

          & .name {
            font-size: 1.4rem;
          }

          & .symbol {
            font-size: 1.2rem;
          }
        }
      }

      & .de-active {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}

html[dir='rtl'] .select-control .select-value {
  background-position-x: 4%;

  & .selected-option {
    right: 0;
  }

  & .input-value {
    right: 16px;
  }
}

html[dir='ltr'] .select-control .select-value {
  background-position-x: 96%;

  & .selected-option {
    left: 0;
  }

  & .input-value {
    left: 16px;
  }
}
