@import '~assets/mixins';

.item {
  min-width: 29.5rem;

  padding: 20px 70px 20px 50px;

  cursor: pointer;

  @media (max-width: $large-screen) {
    padding: 16px;
  }

  &.active {
    background-color: $primary-active;

    & .icon svg path {
      fill: $secondary;
    }

    & .text {
      color: $secondary;
    }
  }

  & .link {
    @include flex-display(
      $alignItems: center,
      $justifyContent: flex-start,
      $gap: 15px
    );

    text-decoration: none;

    pointer-events: none;
  }

  & .icon svg {
    width: 30px;

    & path {
      fill: $primary-light;
    }
  }

  & .text {
    color: $primary-light;
  }
}
