@import '~assets/variables';
@import '~assets/mixins';

.table {
  width: 100%;

  table-layout: fixed;

  border-spacing: 0;

  @media (max-width: $medium-tablet-screen) {
    table-layout: auto;
  }

  & .head {
    background-color: $primary-header;
    color: $primary-light;

    & .header {
      font-weight: 400;

      padding: 16px;

      border-bottom: 1px solid $quaternary-border;

      @media (max-width: $tablet-screen) {
        padding: 14px;
      }
    }
  }

  & .row {
    &:last-child .cell {
      border-bottom: none;
    }

    & .cell {
      color: $primary-light;

      padding: 16px;

      overflow-wrap: break-word;

      border-bottom: 2px solid $quaternary-border;

      @media (max-width: $tablet-screen) {
        padding: 14px;
      }
    }
  }
}

html[dir='rtl'] .table .head {
  text-align: right;
}

html[dir='ltr'] .table .head {
  text-align: left;
}
