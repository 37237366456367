@import '~assets/variables';
@import '~assets/mixins';

.container {
  @include flex-display(
    $alignItems: center,
    $flexDirection: column,
    $gap: 12px
  );

  padding: 16px;

  border: 2px solid $primary-border;
  border-radius: 4px;

  & .row {
    @include flex-display(
      $alignItems: center,
      $justifyContent: space-between,
      $gap: 8px
    );

    width: 100%;

    & .title,
    & .value {
      color: $primary-light;
    }

    & .value {
      direction: ltr;

      word-break: break-all;
    }
  }
}
