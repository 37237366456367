@import '~assets/variables';
@import '~assets/mixins';

.snackbar-area {
  z-index: 10;

  & .success {
    background-color: $primary-valid;
  }

  & .error {
    background-color: $primary-error;
  }

  & .close {
    cursor: pointer;

    & svg {
      width: 10px;
      height: 10px;
    }
  }

  & .position {
    position: fixed;
    top: 12px;

    padding: 10px 20px;

    transition: transform 0.6s ease-in-out;
  }

  & .inner {
    @include flex-display($justifyContent: space-between, $gap: 8px);

    font-size: $size-very-small;

    position: relative;

    min-width: 250px;

    color: $primary-dark;

    padding: 16px;

    border-radius: 5px;

    & svg {
      stroke: $primary-dark;
    }
  }
}

html[dir='rtl'] {
  @keyframes toast-right {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  & .position {
    right: 12px;

    animation: toast-right 0.7s;
  }
}

html[dir='ltr'] {
  @keyframes toast-left {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  & .position {
    left: 12px;

    animation: toast-left 0.7s;
  }
}
