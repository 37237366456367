@import '~assets/variables';
@import '~assets/mixins';

.items {
  @include grid-display(
    $columns: repeat(3, 1fr),
    $gap: 20px,
    $alignItems: center
  );

  color: $primary-light;

  min-height: 16rem;

  border: solid 1px $primary;
  border-radius: 4px;

  overflow: hidden;

  @media (max-width: $medium-tablet-screen) {
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;

    min-height: unset;
    height: 12.9rem;
  }

  & .icon-container {
    @include flex-display($alignItems: center, $justifyContent: center);

    background-color: $senary-theme;

    width: 10rem;
    height: 100%;

    @media (max-width: $medium-tablet-screen) {
      width: 7rem;
    }

    & svg {
      fill: $secondary-text;

      width: 50px;
      height: 50px;

      @media (max-width: $medium-tablet-screen) {
        width: 40px;
        height: 40px;
      }
    }
  }

  & .info-container {
    @include flex-display($flexDirection: column, $gap: 16px);

    grid-column: 2/4;

    @media (max-width: $medium-tablet-screen) {
      grid-column: 2/6;
      gap: 4px;
    }

    & .value-container {
      & .detail {
        @include flex-display($alignItems: center, $gap: 4px);
      }
    }

    & .label,
    & .gain,
    & .loss,
    & .currency {
      @include flex-display(
        $alignItems: center,
        $justifyContent: space-between,
        $gap: 10px
      );
    }

    & .gain {
      color: $primary-valid;
    }

    & .loss {
      color: $primary-sell;
    }

    & > button {
      background-image: linear-gradient(to left, $primary, $primary-error);

      & > span {
        font-size: 1.3rem;
      }
    }
  }
}

html[dir='rtl'] .items {
  padding-left: 30px;

  @media (max-width: $medium-tablet-screen) {
    padding-left: 10px;

    & .icon-container {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

html[dir='ltr'] .items {
  padding-right: 30px;

  @media (max-width: $medium-tablet-screen) {
    padding-right: 10px;

    & .icon-container {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}
