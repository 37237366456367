@import '~assets/variables';
@import '~assets/mixins';

.button {
  padding: 3px 25px;

  border-width: 1px;
  border-style: solid;
  border-radius: 8px;

  user-select: none;

  height: 4.8rem;

  @media (max-width: $x-small-mobile-screen) {
    height: auto;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    & span {
      pointer-events: none;
    }
  }

  &.large {
    width: 100%;
    height: 48px;

    @media (max-width: $tablet-screen) {
      padding: 3px 12px;
    }

    padding: 8px 25px;

    & .text {
      font-size: $size-default;

      @media (max-width: $x-small-mobile-screen) {
        font-size: $size-very-small;
      }

      & .innerText {
        font-size: $size-xx-small;
        margin-right: 4px;
      }
    }
  }

  &.primary {
    background: linear-gradient(259deg, $primary, $secondary);

    color: $primary-dark;
    border-width: 0;
  }

  &.disabled {
    background-color: $secondary-dark;
    color: $tertiary-theme;
    opacity: 0.5;

    border-color: $secondary-dark;
  }

  &.primary-outline {
    background-color: transparent;
    color: $primary;

    border-color: $primary;
  }

  &.secondary {
    background-color: $primary-light;
    color: $tertiary-theme;

    border-color: $primary-light;

    &.secondary-text {
      color: $secondary-text;
    }
  }

  &.secondary-outline {
    background-color: transparent;
    color: $primary-light;

    border-color: $primary-light;
  }

  &.tertiary {
    background-color: $tertiary-text;
    color: $primary-border;

    border-color: $tertiary-text;
  }

  &.link {
    font-family: $font-Regular;

    background-color: transparent;
    color: $primary-light;

    padding: 0;

    border: none;

    outline: none;

    &.link-large {
      width: 100%;
    }

    &.link-primary {
      color: $primary;
    }
  }

  &.sell {
    background-color: $primary-sell;
    color: $primary-light;

    border-color: $primary-sell;
  }

  &.buy {
    background-color: $primary-valid;

    border-color: $primary-valid;
  }

  & .container {
    @include flex-display(center, center, $gap: 12px);

    @media (max-width: $tablet-screen) {
      gap: 12px;
    }
  }

  & .icon {
    @include flex-display($alignItems: center);

    & svg {
      width: 20px;
      height: 20px;

      @media (max-width: $tablet-screen) {
        width: 16px;
        height: 16px;
      }
    }
  }

  & .text {
    font-size: $size-small;

    white-space: nowrap;

    @media (max-width: $x-small-mobile-screen) {
      font-size: $size-very-small;

      white-space: normal;
    }
  }
}
