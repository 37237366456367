@import '~assets/variables';
@import '~assets/mixins';

.buttons-container {
  @include grid-display($columns: repeat(3, 1fr), $gap: 16px);

  margin-top: 32px;

  @media (max-width: $tablet-screen) {
    gap: 12px;

    margin-top: 28px;
  }

  & button:first-child {
    grid-column: 1/2;
  }

  & button:last-child {
    grid-column: 2/4;
  }
}
