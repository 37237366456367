@import '~assets/variables';
@import '~assets/mixins';

.wizard {
  & .header {
    @include flex-display(
      $alignItems: center,
      $justifyContent: flex-start,
      $flexDirection: column,
      $gap: 0
    );

    @media (max-width: $tablet-screen) {
      flex-direction: row;
      gap: 14px;
    }

    & .list {
      @include flex-display($alignItems: center, $gap: 24px);

      width: 100%;

      list-style: none;

      & .item {
        @include flex-display(
          $alignItems: center,
          $justifyContent: space-between
        );

        padding-bottom: 16px;

        &.active {
          border-bottom: 2px solid $primary-light;
        }

        & .step {
          @include flex-display($alignItems: center, $justifyContent: center);

          width: 24px;
          height: 24px;

          border-radius: 50%;

          @media (max-width: $tablet-screen) {
            font-size: 1.8rem;

            width: 20px;
            height: 20px;
          }

          &.current {
            width: 32px;
            height: 32px;

            background-color: $primary-light;

            @media (max-width: $tablet-screen) {
              width: 28px;
              height: 28px;
            }

            & .step-number {
              font-size: 1.6rem;
            }
          }

          &.valid {
            background-color: $primary-valid;
          }

          &.disabled {
            background-color: $primary-disabled;
          }

          & .step-number {
            font-size: 1.4rem;
            line-height: 0.7rem;

            color: $secondary-theme;

            & > svg {
              width: 22px;
            }
          }
        }

        & .disabled {
          color: $primary-disabled;
        }

        & .valid {
          color: $primary-valid;
        }

        & .current {
          color: $primary-light;
        }
      }
    }

    & .border-line {
      background-color: $primary-border;

      height: 1px;
      width: 100%;
    }

    & .step-indicator,
    & .step-name {
      color: $primary-light;
    }

    & .step-indicator {
      @include flex-display($alignItems: center, $gap: 4px);

      padding: 16px 12px;

      border: 4px solid $primary-light;
      border-radius: 50%;
    }
  }

  & .body {
    @include flex-display($flexDirection: column, $gap: 12px);

    padding: 48px 0;

    @media (max-width: $tablet-screen) {
      padding: 32px 0;
    }
  }
}

html[dir='rtl'] .wizard {
  & .step {
    margin-left: 8px;

    @media (max-width: $tablet-screen) {
      margin-left: 4px;
    }
  }
}

html[dir='ltr'] .wizard {
  & .step {
    margin-right: 8px;

    @media (max-width: $tablet-screen) {
      margin-right: 4px;
    }
  }
}
