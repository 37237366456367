@import '~assets/variables';
@import '~assets/mixins';

.table-pagination {
  @include flex-display($alignItems: center);

  width: 100%;

  & .table-pages-indexing {
    @include flex-display($alignItems: center);

    width: auto;
    padding: 15px 30px;

    color: $primary-text;
    font-size: 14px;
  }

  & .navigation-buttons-container {
    @include flex-display($alignItems: center);

    width: auto;
    padding: 15px;

    color: $primary-text;

    & .table-pagination-list {
      @include flex-display();

      & .table-pagination-item {
        list-style-type: none;

        color: $primary-text;

        border: 1px solid $septenary-border;

        cursor: pointer;

        padding: 17.5px 15px;
      }

      & .active {
        background-color: $primary-border;
      }
    }

    & .pagination-btn {
      color: $primary-text;

      border: 1px solid $septenary-border;

      cursor: pointer;

      padding: 10px;

      & svg {
        width: 10px;
        fill: $primary-text;
      }
    }
    & .next {
      & svg {
        transform: rotate(90deg);
      }
    }
    & .prev {
      & svg {
        transform: rotate(-90deg);
      }
    }

    & .pagination-btn:disabled {
      color: $primary-disabled;
      border-color: $primary-disabled;

      cursor: not-allowed;
    }
  }
}
