@import '~assets/variables';
@import '~assets/mixins';

.container {
  @include flex-display($alignItems: center, $justifyContent: center);

  width: 100%;

  &.spacing {
    padding: 48px 30px 70px 70px;

    @media (max-width: $large-screen) {
      padding: 20px 70px;
    }

    @media (max-width: $tablet-screen) {
      padding: 20px 16px;
    }
  }

  &.border {
    border: 2px solid $octonary-border;

    & .inner-container {
      & .header {
        border-color: $octonary-border;
      }
    }
  }

  & .inner-container {
    background-color: $quaternary-theme;

    width: 100%;

    @media (max-width: $tablet-screen) {
      background-color: transparent;
    }

    &.spacing {
      margin: 48px 70px;

      border-radius: 8px;

      @media (max-width: $tablet-screen) {
        margin: 26px 16px;
      }
    }

    & .header {
      @include flex-display(
        $alignItems: center,
        $justifyContent: space-between
      );

      height: 90px;

      padding: 24px 32px;

      border-bottom: 2px solid $primary-border;

      @media (max-width: $tablet-screen) {
        height: auto;

        gap: 12px;
        flex-wrap: wrap;

        padding: 12px 16px;
      }

      &.no-padding {
        height: auto;

        padding: unset;
      }

      & h3 {
        color: $primary-light;
      }
    }

    & .body {
      padding: 32px;

      overflow: auto;
      scrollbar-color: $scrollbar-color $scrollbar-background-color;
      scrollbar-width: thin;

      @media (max-width: $tablet-screen) {
        padding: 16px;
      }

      &::-webkit {
        &-scrollbar {
          background: $scrollbar-background-color;
          width: 14px;
        }

        &-scrollbar-thumb {
          background: $scrollbar-color;
        }
      }

      &.flex-column-body {
        @include flex-display($flexDirection: column, $gap: 24px);
      }

      & .not-found {
        text-align: center;

        color: $primary-light;
      }
    }
  }
}
