@import './assets/variables';
@import './assets/mixins';

html {
  font-size: 10px;
  line-height: 1.15;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  @media (max-width: $tablet-screen) {
    font-size: 9px;
    line-height: 1.14;
  }
}

html[dir='rtl'] {
  direction: rtl;
}

html[dir='ltr'] {
  direction: ltr;
}

body {
  font-family: $font-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  min-height: 100vh;
  height: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  margin: 0;

  background-color: $primary-theme;
  transition: background-color 0.3s;
}

*,
*:before,
*:after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

[aria-hidden='true'] {
  pointer-events: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;

  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  color: $primary-light;
}

a,
button,
input {
  -webkit-tap-highlight-color: transparent;
}

a {
  background-color: transparent;
}

[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}

button,
select {
  text-transform: none;
}

button,
input {
  overflow: visible;
}

input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;

  margin: 0;
}

button,
a.button {
  font-family: $font-Bold;
  text-decoration: none;
  line-height: 2;

  cursor: pointer;

  background: transparent;

  padding: 0;

  border: none;
  border-radius: 0;

  -webkit-appearance: none;
}

ol,
ul {
  margin: 0;
  padding: 0;
}

img {
  color: $primary-light;
}

footer {
  margin-top: auto;
}

#root {
  @include flex-display($flexDirection: column);

  min-height: 100vh;
}

.eng-font {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  &::placeholder {
    font-family: $font-Regular;
  }
}

.d-none {
  display: none;
}

.direction-ltr {
  direction: ltr;
}

.direction-rtl {
  direction: rtl;
}

.heading-01 {
  font: 400 3rem/4.5rem $font-Regular;

  color: $primary-light;
}

.heading-02 {
  font: 400 2.4rem/3.5rem $font-Regular;

  color: $primary-light;
}

.heading-03,
.desc-01 {
  font: 400 2rem/3.2rem $font-Regular;

  color: $primary-light;
}

.heading-04,
.desc-02 {
  font: 400 1.6rem/2.5rem $font-Regular;

  color: $primary-light;
}

.desc-03 {
  font: 400 1.4rem/2.3rem $font-Regular;

  color: $primary-light;
}

.desc-04 {
  font: 400 1.2rem/2.1rem $font-Regular;

  color: $primary-light;
}

.shadow-01 {
  box-shadow: 0 0 4px 2px $primary-shadow;
}

.form-error {
  display: grid;

  font-size: 1.2rem;

  color: $primary-sell;

  margin-top: 16px;

  @media (max-width: $tablet-screen) {
    font-size: 1rem;

    margin-top: 12px;
  }
}

.bordered-title {
  width: fit-content;

  padding-bottom: 12px;

  border-bottom: 1px solid $quinary-border;

  @media (max-width: $tablet-screen) {
    padding-bottom: 10px;
  }
}

.bordered-title-center {
  width: fit-content;

  border-bottom: 1px solid $quinary-border;

  padding: 0 35px 12px 35px;
  @media (max-width: $tablet-screen) {
    padding: 0 28px 12px 28px;
  }
}

.recharts-default-tooltip {
  background: $senary !important;
}

[class*='team'],
[class*='testimonial'] {
  & .slick-slide {
    margin: 0 2rem;

    @media (max-width: $tablet-screen) {
      margin: 0 1.5rem;
    }
  }
}
.recharts-text.recharts-label {
  fill: $secondary-dark;
  font-size: $size-medium;
}
.modal-table {
  width: 100%;

  border: 1px solid;
  box-sizing: border-box;
  border-collapse: collapse;

  text-align: center;
  font-size: 1rem;

  margin-top: 10px;
  margin-bottom: 10px;
  & th,
  hr,
  td {
    border: 1px solid #eee;
  }
}

html[dir='rtl'] {
  & .bordered-title {
    padding-left: 35px;

    @media (max-width: $tablet-screen) {
      padding-left: 28px;
    }
  }

  [class*='team'],
  [class*='testimonial'] {
    & .slick-list {
      margin-left: -50rem;

      @media (max-width: $mobile-screen) {
        margin-left: -1rem;
      }

      @media (max-width: $x-small-mobile-screen) {
        margin-left: -2rem;
      }
    }
  }
}

html[dir='ltr'] {
  & .bordered-title {
    padding-right: 35px;

    @media (max-width: $tablet-screen) {
      padding-right: 28px;
    }
  }

  [class*='team'],
  [class*='testimonial'] {
    & .slick-list {
      margin-right: -50rem;

      @media (max-width: $mobile-screen) {
        margin-right: -1rem;
      }

      @media (max-width: $x-small-mobile-screen) {
        margin-right: -2rem;
      }
    }
  }
}
