@import '~assets/variables';
@import '~assets/mixins';

.list {
  width: 100%;

  list-style: none;

  user-select: none;

  @media (max-width: $medium-tablet-screen) {
    @include flex-display(
      $alignItems: center,
      $justifyContent: center,
      $flexDirection: column,
      $gap: 32px
    );

    gap: 16px;
  }

  .intelligent-basket {
    font-weight: bold;
  }
}
