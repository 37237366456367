@import '~assets/variables';

.logo {
  position: relative;

  @media (max-width: $tablet-screen) {
    width: 130px;
    height: 55px;
  }
}

header {
  & .logo {
    & picture img {
      max-width: 100px;
    }
  }
}

footer {
  & .logo {
    & picture img {
      width: 142px;
      height: 75px;

      @media (max-width: $tablet-screen) {
        width: 101px;
        height: 55px;
      }
    }
  }
}
