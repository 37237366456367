@import '~assets/variables';
@import '~assets/mixins';

.container {
  @include flex-display(center, center);

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 7;

  & .overlay {
    position: absolute;

    background: $secondary-border;

    width: 100%;
    height: 100%;

    opacity: 0.5;

    z-index: 8;
  }

  & .modal {
    background-color: $secondary-theme;

    width: 465px;
    height: auto;

    z-index: 9;

    @media (max-width: $mobile-screen) {
      width: 95%;
    }

    &.full-screen {
      width: 100%;
      height: 100%;

      & .header {
        @include flex-display(
          $alignItems: center,
          $justifyContent: flex-end,
          $flexDirection: row-reverse,
          $gap: 16px
        );

        border-width: 4px;

        & svg {
          fill: $primary-light;
        }
      }

      & .body {
        height: calc(100% - 80px);
        max-height: unset;

        @media (max-width: $mobile-screen) {
          height: calc(100% - 64px);
        }
      }
    }

    & .header {
      @include flex-display(
        $alignItems: center,
        $justifyContent: space-between
      );

      height: 80px;

      padding: 24px 32px;

      border-bottom: 1px solid $secondary-border;

      @media (max-width: $mobile-screen) {
        height: 64px;

        padding: 16px;
      }

      &.sell {
        border-top: 8px solid $primary-sell;
      }

      &.buy {
        border-top: 8px solid $primary-valid;
      }

      &.intelligent-basket {
        border-top: 8px solid $secondary;
      }

      & .title {
        color: $primary-light;
      }

      & .close {
        cursor: pointer;

        & svg {
          width: 20px;
          height: 20px;

          @media (max-width: $tablet-screen) {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    & .body {
      @include scrollbar();

      font-size: 1.6rem;
      line-height: 2.5rem;
      color: $primary-light;

      min-height: 15vh;
      max-height: 75vh;

      padding: 32px;

      overflow-y: auto;

      @media (max-width: $tablet-screen) {
        font-size: 1.4rem;
      }

      @media (max-width: $mobile-screen) {
        padding: 16px;
      }

      &.flex-column {
        @include flex-display(
          $alignItems: stretch,
          $justifyContent: flex-start,
          $flexDirection: column,
          $gap: 24px
        );
      }

      &.sell {
        border-top: 8px solid $primary-sell;
      }

      &.buy {
        border-top: 8px solid $primary-valid;
      }

      &.warning,
      &.caution {
        border-top: 8px solid $tertiary-border;
      }

      &.intelligent-basket {
        border-top: 8px solid $secondary;
      }

      &.caution {
        background: url('~assets/icons/warning.svg');
        background-repeat: no-repeat;
        background-size: 110px 100px;
        background-position: top 20px center;
      }
    }

    & .footer {
      @include buttons-container();

      @include grid-display(
        $columns: repeat(3, 1fr),
        $alignItems: center,
        $gap: 16px
      );

      height: 95px;

      padding: 24px 32px;

      border-top: 1px solid $secondary-border;

      @media (max-width: $tablet-screen) {
        gap: 14px;
      }

      @media (max-width: $mobile-screen) {
        padding: 24px 16px;
      }
    }
  }
}
